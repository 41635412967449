import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "transaction-sdk",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#transaction-sdk",
        "aria-label": "transaction sdk permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Transaction SDK`}</h1>
    <p>{`Our Transaction SDK is a JavaScript based Software Development Kit which allows you to integrate Medipass’ `}<strong parentName="p">{`create claim & payment`}</strong>{` flow with 61 unique health funds and payment options via a self-contained UI (a lightbox modal or an embedded frame).`}</p>
    <p>{`The SDK allows you to easily process integrated invoices across a range of supported claim and payment channels without the need to develop funder specific logic flows, adjudication rules and error handling.`}</p>
    <p>{`Medipass handles claim validations and alerts end users to potential invoicing problems within the self-contained UI. Health claim transactions are processed in real-time and most health funds provide instant claim decisions. Patient payment transactions are processed in real-time with a fully integrated practice to patient payment request workflow, including credit/debit card and ApplePay/Google Pay via SMS request.`}</p>
    <p>{`To simplify ongoing support for your providers, we include a real-time chat and support capability. The chat links to our help system which identifies the business and status of transactions. This means that claim and payment transaction issues and inquiries can be easily directed to us rather than your help desk.`}</p>
    <h5 {...{
      "id": "lightbox-modal-example-default",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#lightbox-modal-example-default",
        "aria-label": "lightbox modal example default permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Lightbox modal example (default)`}</h5>
    <p><img parentName="p" {...{
        "src": "/images/playground-example.png",
        "alt": null
      }}></img></p>
    <h5 {...{
      "id": "embedded-frame-example",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#embedded-frame-example",
        "aria-label": "embedded frame example permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Embedded frame example`}</h5>
    <p><img parentName="p" {...{
        "src": "/images/embedded.png",
        "alt": null
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      